.cta-section {
  background-color: #fefefe !important;
  padding-top: 5rem;
  padding-bottom: 12.8rem;
}

.cta-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  background-image: linear-gradient(to right bottom, #8b0000cc, #bb0000cc);
  border-radius: 1.2rem;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.219);
  overflow: hidden;
  padding: 3rem;
  color: #ffffff;
  max-width: 120rem;
  margin: 0 auto;
}

.cta-content {
  padding: 2rem;
}

.cta-content h2 {
  font-size: 2.4rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 1.2rem;
}

.cta-content p {
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
  line-height: 1.5;
  color: #f0e6e6;
}

.cta-form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;
  margin-bottom: 2rem;
}

.cta-form-group label {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  display: block;
  color: #f0e6e6;
}

.cta-form-group input,
.cta-form-group select,
.cta-form-group textarea {
  width: 100%;
  padding: 0.8rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 0.8rem;
  background-color: #f2f2f2;
  color: #333333;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
}

.cta-form-group input::placeholder {
  color: #999999;
}

.cta-submit {
  width: 100%;
  padding: 1.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  background-color: #e0e0e0;
  color: #444444;
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: background-color 0.3s;
  text-transform: uppercase;
  box-shadow: inset 0 0 0 1px #666666;
  height: 100%;
}

.cta-submit:hover,
.cta-submit:active {
  background-color: #c0c0c0;
  box-shadow: inset 0 0 0 1px #444444;
  color: #333333;
}

.cta-terms {
  font-size: 1.2rem;
  color: #f0e6e6;
  text-align: center;
}

.cta-terms a {
  color: #ffffff;
  text-decoration: underline;
}

.cta-image {
  position: relative;
  overflow: hidden;
  border-radius: 0 1.2rem 1.2rem 0;
}

.cta-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.75) contrast(1.1);
}

.cta-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(139, 0, 0, 0.45), rgba(255, 255, 255, 0.1));
  z-index: 1;
}

.success-message {
  padding: 1.6rem;
}
