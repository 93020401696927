.testimonials-section {
  background-color: #fefefe;
  padding: 12.8rem 0;
}

.testimonial-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10rem;
  padding: 2rem;
  margin: 2rem;
  background-color: #f9f9f9;
  border-radius: 9px;
  align-items: center;
  justify-content: center;
}

.testimonial-section-heading {
  padding-bottom: 8rem;
}

.testimonial-author {
  padding-top: 3rem;
  color: #727272;
}

.testimonial-logo {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.testimonial-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
  pointer-events: none;
}

.award-section {
  display: flex;
  grid-column: 1/ -1;
}

.award-section img {
  width: 100%;
  height: auto;
  object-fit: contain;
  pointer-events: none;
}
