/*****************************/
/*     Navigation CSS        */
/*****************************/

.nav-container {
  max-width: 100vw;
  top: 0;
  background-color: #ffffffdf;
  padding: 1rem 2rem;
  z-index: 9999;
}

.nav-sticky {
  position: sticky;
  background-color: #ffffffdf;
  padding: 0.5rem 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.03);
}

.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo {
  height: 6.2rem;
  cursor: pointer;
}

.main-nav-list {
  display: flex;
  list-style: none;
  gap: 3.2rem;
  padding: 1rem;
}

.main-nav-link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #363636;
  font-weight: 600;
  transition: all 0.3s;
  cursor: pointer;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #bb0000;
}

/* Mobile Navigation */
.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #363636;
}

.hidden {
  display: none !important;
}

.icon-mobile-nav[name='close-nav'] {
  display: none;
}

.nav-open .icon-mobile-nav[name='close-nav'] {
  display: block;
}

.nav-open .icon-mobile-nav[name='open-nav'] {
  display: none;
}
