/*****************************/
/* At 1400px (Landscape Tablets) */
/*****************************/
@media (max-width: 87.5em) {
  .special-container {
    max-width: 100rem;
  }
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/*****************************/
/* BELOW 1100px (Landscape Tablets) */
/*****************************/
@media (max-width: 68.75em) {
  html {
    font-size: 56.25%;
  }
}

/*****************************/
/* BELOW 950 Portrait Tablets */
/*****************************/
@media (max-width: 59.375em) {
  .heading-secondary {
    font-size: 3.6rem;
  }
  .feature-text {
    font-size: 1.8rem;
  }

  .hero-title {
    font-size: 3.6rem;
  }
  .hero-description {
    font-size: 1.8rem;
  }
  .Bubbles {
    display: none;
  }
  .ticker-img {
    height: 8rem;
    max-height: 8rem;
    width: auto;
    max-width: 16rem;
    margin-right: 2rem;
  }
  .section-heading {
    font-size: 1.6rem;
  }

  /* Mobile Nav */
  .btn-mobile-nav {
    display: block;
    z-index: 9999;
  }

  .cta-button.hidden {
    display: block !important;
    font-size: 3rem;
  }

  .non-mobile {
    display: none;
  }

  .main-nav-list {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    gap: 4.8rem;
    transition: all 0.5s;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .main-nav-link,
  .main-nav-link:visited {
    font-size: 3rem;
  }

  .main-nav-link:hover,
  .main-nav-link:active {
    font-size: 3rem;
  }

  .nav-open .main-nav-list {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .pricing-table {
    grid-template-columns: 1fr;
  }
  .industries-tablist {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}

/*****************************/
/* BELOW 704 (Larger Mobiles) */
/*****************************/

@media (max-width: 44em) {
  .hero-section {
    background-size: contain;
    background-position: center;
  }

  .hero-description {
    font-size: 2rem;
    padding: 0% 10%;
  }

  .feature-text {
    font-size: 2rem;
    padding: 3rem;
  }

  .steps-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'dividing-line'
      'illustration-box'
      'info-block';
    text-align: center;
    gap: 10rem;
    margin-bottom: 4rem;
  }

  .dividing-line {
    grid-area: dividing-line;
  }

  .illustration-box {
    grid-area: illustration-box;
    width: 15rem;
    height: auto;
  }

  .illustration-box img {
    max-width: 100%;
    height: auto;
  }

  .info-block {
    grid-area: info-block;
    font-size: 2.4rem;
    line-height: 1.6;
  }

  .how-it-works-title {
    font-size: 2.4rem;
  }

  .info-block p {
    font-size: 2rem;
  }

  .how-it-works::before {
    display: none;
  }

  .dividing-line span {
    background-color: transparent;
  }

  .testimonial-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5rem;
  }

  .testimonial-text {
    font-size: 2rem;
  }

  .testimonial-logo {
    display: none;
  }

  .cta-container {
    grid-template-columns: 1fr;
    padding: 0;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .cta-image {
    width: 100%;
    height: auto;
    border-radius: 1.2rem;
    overflow: hidden;
    grid-row: 1;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
  }

  .cta-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .industries-section {
    padding: 8rem 0;
  }

  .grid--footer {
    grid-template-columns: 1fr;
  }

  .card-container {
    grid-template-columns: 1fr;
  }

  .industries-tablist {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  .steps-container:nth-child(even) .info-block {
    text-align: left;
  }
}

/*****************************/
/* BELOW 600px (Mobile) */
/*****************************/

@media (max-width: 34.375em) {
  .hero-buttons {
    gap: 2rem;
  }

  .hero-title {
    padding: 0% 5%;
  }

  .hero-subtitle {
    padding: 0% 10%;
  }

  .hero-description {
    font-size: 2rem;
    padding: 0% 10%;
    margin: 0 auto;
  }

  .feature-text {
    font-size: 2rem;
    padding: 3rem;
  }

  .industries-tablist {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .industries-pitch {
    padding: 0% 10%;
  }

  .industries-description {
    padding: 0% 10%;
  }

  .heading-secondary {
    font-size: 3rem;
  }

  .how-it-works-title {
    font-size: 2.4rem;
  }
}
