.how-it-works-section {
  padding-bottom: 12.8rem;
  background-color: #fefefe;
}

.how-it-works {
  text-align: center;
  padding: 2.5rem 1.25rem; /* 40px 20px */
  background-color: #fefefe !important;
  position: relative; /* Make the section relative so the line can be absolute */
}

/* Create the continuous vertical line */
.how-it-works::before {
  content: "";
  position: absolute;
  width: 0.125rem; /* 2px */
  height: 100%;
  background-color: #e0e4e8;
  left: 50%; /* Center the line */
  top: 0;
  z-index: 0; /* Place it behind the step numbers */
  transform: translateX(-50%);
}

.steps-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr; /* Info - Divider - Illustration */
  align-items: center; /* Center all items vertically */
  justify-content: center;
  gap: 2.5rem; /* 40px */
  margin-bottom: 2.5rem; /* 40px */
  position: relative;
}

.info-block {
  text-align: center;
  max-width: 30rem; /* 480px */
  padding: 0 1.25rem; /* 0 20px */
}

.how-it-works-title {
  font-size: 1.8rem;
  font-weight: 600;
}

.illustration-box {
  width: 30rem;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.illustration-box img {
  max-width: 100%;
  height: auto;
  pointer-events: none;
}

.dividing-line {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.dividing-line span {
  background-color: #fefefe;
  border: 1px solid #e0e4e8;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  color: #666;
  z-index: 1;
  position: relative;
  font-size: 3rem;
}

/* Centered content for all steps */
.steps-container:nth-child(odd) .info-block,
.steps-container:nth-child(even) .info-block,
.steps-container:nth-child(odd) .illustration-box,
.steps-container:nth-child(even) .illustration-box {
  justify-self: center;
}

.steps-container:nth-child(odd) .info-block {
  text-align: left;
}

.steps-container:nth-child(even) .info-block {
  text-align: right;
}

.illustration-box {
  width: 30rem;
  height: 30rem;
  object-fit: contain;
}
