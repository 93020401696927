/*****************************/
/*     Footer Section CSS    */
/*****************************/

.section-footer {
  padding: 4rem 0;
  background-color: #fff;
  border-top: 1px solid #666;
  border-bottom: 1px solid #bb0000;
}

.grid--footer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4rem;
  align-items: start;
}

.logo-col {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.footer-logo {
  display: block;
  height: 9.6rem;
  width: 9.6rem;
  margin-bottom: 2.4rem;
}

.social-links {
  list-style: none;
  display: flex;
  gap: 1.6rem;
}

.social-icon {
  height: 2.4rem;
  width: 2.4rem;
}

.compliance-col {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.compliance-col img {
  pointer-events: none;
}

.hubzone-badge {
  width: 100%;
  height: 10rem;
}

.compliance-badge {
  width: 100%;
  height: 8rem;
}

.contacts {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.address-text {
  font-size: 1.6rem;
  color: #555;
}

.footer-heading {
  font-size: 1.8rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 2rem;
}

.footer-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.footer-link,
.footer-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #555;
  font-weight: 400;
  font-size: 1.6rem;
  transition: color 0.3s;
}

.footer-link:hover,
.footer-link:active {
  color: #bb0000;
}
