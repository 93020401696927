/* Branding Decisions */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* General Layout CSS */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  color: #333;
  background-color: #fefefe;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Typography */
h1 {
  font-size: 5.2rem;
  line-height: 1.05;
  margin-bottom: 3.2rem;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.1;
  margin-bottom: 2.4rem;
}

h3 {
  font-size: 3rem;
  line-height: 1.15;
  margin-bottom: 1.6rem;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 8rem;
  font-weight: 500;
}

.section-heading {
  font-weight: 600;
  text-transform: uppercase;
  color: #bb0000;
  margin-bottom: 5rem;
}

p {
  font-size: 1.6rem;
}

/* Container */
.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

/* Grid System */
.grid {
  display: grid;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

/* Spacing Helpers */
.ml-1 {
  margin-left: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.pt-3 {
  padding-top: 3rem;
}

.dflex {
  display: flex;
}

.center-content {
  justify-content: center;
  align-items: center;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}
