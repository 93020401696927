/* Container for the scrolling ticker */
.logo-banner-section {
  background-color: #fefefe;
  padding: 6rem 0;
}

.trusted-by {
  background-color: #fefefe;
  padding: 3rem 0;
  font-weight: 600;
}

.trusted-by-title {
  text-align: center;
  font-size: 2rem;
  color: #666;
  margin-bottom: 4rem;
  padding-top: 6rem;
}

.logo-banner {
  width: 100%;
  overflow: hidden;
  background-color: #fefefe;
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styling for each logo image */
.ticker-img {
  height: 10rem;
  max-height: 10rem;
  width: auto;
  max-width: 20rem;
  margin-right: 4rem;
  filter: grayscale(100%) brightness(1.2);
  opacity: 50%;
  transition: filter 0.3s ease-in-out;
  object-fit: contain;
}

/* Hover effect to restore color */
.ticker-img:hover {
  filter: grayscale(0%);
  opacity: 100%;
}
