/* Pricing Section Container */
.pricing-section {
  background-color: #fefefe !important;
  background: radial-gradient(
    ellipse 80% 40% at center 60%,
    /* Moves the gradient ellipse down */ rgba(255, 102, 120, 0.3) 30%,
    /* Brand red color */ rgba(255, 102, 120, 0) 70%
      /* Fully transparent by 70% */
  );
  padding: 12.8rem 0;
  position: relative;
}

/* Pricing Headings Container */
.pricing-headings {
  text-align: center;
  max-width: 80rem;
  margin: 0 auto;
}

/* Feature Text Style */
.feature-text {
  font-size: 2rem;
  color: #666;
  margin-bottom: 6rem;
}

/* Pricing Table Layout */
.pricing-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8rem;
  margin-top: 6.4rem;
}

/* Individual Pricing Card Styles */
.pricing-card {
  background-color: #fff;
  padding: 3.2rem;
  border-radius: 1rem;
  box-shadow: 0 0 1.6rem rgba(0, 0, 0, 0.1);
  transition: transform 2s ease-out, opacity 2s ease-out;
  opacity: 0;
  transform: translateX(0);
}

/* Initial Plan Card - Sliding from the Left */
.price-initial {
  transform: translateX(-5rem);
}

/* Enterprise Plan Card - Sliding from the Right */
.enterprise {
  transform: translateX(5rem);
  overflow: hidden;
}

/* Visibility State for Pricing Cards - Slide into view */
.pricing-card.visible {
  opacity: 1;
  transform: translateX(0);
}

/* Heading Style Inside Pricing Cards */
.pricing-heading {
  margin-bottom: 5rem;
  border-bottom: 1px solid #bb0000;
  font-size: 3rem;
  font-weight: 600;
}

/* Pricing Details Section */
.pricing-details {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 4.8rem 4.8rem;
}

/* Individual Item Style in Pricing Details */
.pricing-details span {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

/* Icon Styling for Available Features */
.pricing-details .icon {
  color: green;
  margin-right: 0.8rem;
}

/* Icon Styling for Unavailable Features */
.icon-unavailable {
  color: red !important;
}

/* Specific Styling for Enterprise Plan Banner */
.enterprise::after {
  content: 'Preferred Plan';
  position: absolute;
  text-transform: uppercase;
  top: 5.5rem;
  right: -7rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  background-color: #bb0000;
  padding: 0.8rem 8rem;
  transform: rotate(45deg);
}
