/* Floating Bubbles Styles */
.bubble {
  position: absolute;
  border-radius: 50%;
  opacity: 0.5;
  z-index: 1;
  animation: float 15s infinite ease-in-out;
}

/* Reversed bubble for more randomness */
.bubble-reverse {
  position: absolute;
  border-radius: 50%;
  opacity: 0.5;
  z-index: 1;
  animation: float-reverse 18s infinite ease-in-out;
}

/* Default Bubble Styles */
.bubble1 {
  width: 12.5rem;
  height: 12.5rem;
  background: radial-gradient(
    circle,
    rgba(102, 102, 102, 0.8) 0%,
    rgba(102, 102, 102, 0) 100%
  );
  top: 20%;
  left: 10%;
  animation-duration: 20s;
}

.bubble2 {
  width: 9.375rem;
  height: 9.375rem;
  background: radial-gradient(
    circle,
    rgba(102, 102, 102, 0.8) 0%,
    rgba(102, 102, 102, 0) 100%
  );
  bottom: 10%;
  right: 15%;
  animation-duration: 16s;
}

.bubble3 {
  width: 15.625rem;
  height: 15.625rem;
  background: radial-gradient(
    circle,
    rgba(187, 0, 0, 0.8) 0%,
    rgba(189, 0, 0, 0) 100%
  );
  bottom: 10%;
  left: 14%;
  animation-duration: 22s;
}

.bubble4 {
  width: 6.25rem;
  height: 6.25rem;
  background: radial-gradient(
    circle,
    rgba(187, 0, 0, 0.8) 0%,
    rgba(187, 0, 0, 0) 100%
  );
  top: 30%;
  right: 20%;
  animation-duration: 12s;
}

.bubble5 {
  width: 3.75rem;
  height: 3.75rem;
  background: radial-gradient(
    circle,
    rgba(187, 0, 0, 0.8) 0%,
    rgba(187, 0, 0, 0) 100%
  );
  top: 40%;
  right: 12%;
  animation-duration: 18s;
}

/* Media Queries for Responsiveness */

/* Medium Screens */
@media (max-width: 48rem) {
  /* 768px */
  .bubble1,
  .bubble2,
  .bubble3,
  .bubble4,
  .bubble5 {
    width: 8.75rem;
    height: 8.75rem;
  }
  .bubble1 {
    top: 25%;
    left: 5%;
    animation-duration: 22s;
  }
  .bubble2 {
    bottom: 5%;
    right: 10%;
    animation-duration: 18s;
  }
  .bubble3 {
    bottom: 15%;
    left: 10%;
    animation-duration: 24s;
  }
  .bubble4 {
    top: 35%;
    right: 15%;
    animation-duration: 14s;
  }
  .bubble5 {
    top: 45%;
    right: 8%;
    animation-duration: 20s;
  }
}

/* Small Screens */
@media (max-width: 30rem) {
  /* 480px */
  .bubble1,
  .bubble2,
  .bubble3,
  .bubble4,
  .bubble5 {
    width: 6.25rem;
    height: 6.25rem;
  }
  .bubble1 {
    top: 30%;
    left: 5%;
    animation-duration: 25s;
  }
  .bubble2 {
    bottom: 5%;
    right: 5%;
    animation-duration: 20s;
  }
  .bubble3 {
    bottom: 20%;
    left: 5%;
    animation-duration: 26s;
  }
  .bubble4 {
    top: 40%;
    right: 10%;
    animation-duration: 16s;
  }
  .bubble5 {
    top: 50%;
    right: 5%;
    animation-duration: 22s;
  }
}

/* Animation for Smooth Scaling */
@keyframes float {
  0% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(-1.25rem, -1.25rem) scale(1.1);
  }
  50% {
    transform: translate(1.875rem, -3.125rem) scale(1);
  }
  75% {
    transform: translate(-0.9375rem, 1.875rem) scale(1.05);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

/* Animation for Reverse Float */
@keyframes float-reverse {
  0% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(1.25rem, 0.9375rem) scale(1.1);
  }
  50% {
    transform: translate(-1.875rem, 2.5rem) scale(0.9);
  }
  75% {
    transform: translate(0.9375rem, -1.875rem) scale(1.05);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}
