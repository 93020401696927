.services-section {
  background-color: #fefefe;
}

.special-container {
  max-width: 140rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-services-container {
  text-align: center;
}

.service-heading {
  font-weight: 600;
  text-transform: uppercase;
  color: #bb0000;
  margin-bottom: 5rem;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates 4 columns */
  gap: 2rem; /* Adds space between the columns */
  justify-items: center; /* Centers items in the grid cells */
}

.service-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28rem;
  height: 26rem;
  margin: 1rem;
  transition: all 0.3s;
}

.service-card svg {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  color: #666666e0;
}

.service-card:hover {
  transform: scale(1.025) translateY(-1.5rem);
}

.service-card:hover .card-title {
  color: #b00;
}

.service-card:hover svg {
  color: #b00;
}

.card-title {
  font-size: 1.85rem;
  font-weight: 600;
}
