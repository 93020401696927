/* PolicyStyles.css */

.policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  color: #363636;
}

.policy-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

.policy-effective-date {
  text-align: center;
  font-size: 1em;
  color: #555;
}

.policy-intro {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.policy-section {
  margin-bottom: 20px;
}

.policy-heading {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.policy-text {
  font-size: 1em;
  color: #666;
  line-height: 1.6;
}

.policy-list {
  padding-left: 20px;
  list-style-type: disc;
  color: #666;
}

.policy-link {
  color: #007bff;
  text-decoration: underline;
}

.policy-contact-info {
  font-size: 1em;
  line-height: 1.6;
  color: #333;
}
