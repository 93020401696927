/* AboutUs.css */

.about-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  color: #363636;
}

.about-title {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #363636;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.about-image {
  width: 100%;
  max-width: 450px;
  height: auto;

  margin-bottom: 20px;
}

.about-text {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
  text-align: left;
}

.about-text p {
  margin-bottom: 15px;
}

/* Responsive styling for larger screens */
@media (min-width: 768px) {
  .about-content {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
  }

  .about-image {
    max-width: 400px;
    margin-bottom: 0;
  }

  .about-text {
    max-width: 480px;
    padding-left: 20px;
  }
}
