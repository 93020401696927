/* App.css */

/* Branding Decisions */

/* 
  --- 01 Typography System ---
  FONT FAMILY SYSTEM
  - Primary: 'Montserrat', sans-serif;
  - Secondary: 'Roboto', sans-serif;
  
  FONT SIZE SYSTEM (px)
  10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

  FONT WEIGHT SYSTEM
    Default: 400
    Medium: 500
    Semi-bold: 600
    Bold: 700

  - LINE HEIGHT SYSTEM
    Default: 1
    Small: 1.05
    Medium: 1.2
    Paragraph default: 1.6

  - LETTER SPACING SYSTEM
    Default: 0
    Small: -0.5px
    Large: 0.75px

  --- 02 Color System ---

  - Primary: 
    #bb0000 Primary Color
    #666 Secondary Color

  - Tints: 
    #dd8080 (lighter red)

  - Shades:
    #333 (dark grey)

  - Accents:

  - Greys:
    #888
    #767676 (lightest gray allowed on #fff)
    #555
    #333

  --- 05 Shadow System ---
  0 2.4rem 4.8rem rgba(0, 0, 0, 0.75);

  --- 06 Border System ---
    Default: 9px solid #666
    Medium: 11px solid #333

  --- 07 White Space System ---
  SPACING SYSTEM (px)
  2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

/* Call to action Buttons Primary */
.cta-button,
.cta-button:visited,
.cta-button-primary {
  display: inline-block;
  background-color: #bb0000;
  font-size: 1.6rem;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  padding: 1.5rem 3rem;
  border-radius: 5rem;
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
}

.cta-button:hover,
.cta-button:active {
  background-color: #dd8080;
}

/* Call to action Buttons Secondary */
.cta-button-secondary {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.5rem 3rem;
  border-radius: 5rem;
  color: #363636;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
}

.cta-button-secondary:hover,
.cta-button-secondary:active {
  background-color: #f5f5f5;
  box-shadow: inset 0 0 0 1px #666;
  color: #666;
}

/*****************************/
/*     Landing Page CSS      */
/*****************************/

.section {
  padding: 100px 20px;
  height: 100vh; /* Optional: Set height to ensure full-page sections */
}

/* !Potato */
.hero-section {
  background-color: #f9f9f9;
}
