/*****************************/
/*     Hero Section CSS      */
/*****************************/

.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f4f4f4;
  overflow: hidden;
  background: linear-gradient(
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0.98)
    ),
    url("../../public/itmlogo.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.hero-content {
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.hero-subtitle {
  font-weight: 600;
  text-transform: uppercase;
  color: #bb0000;
  margin-bottom: 0.5rem;
}

.hero-title {
  color: #363636;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.hero-description {
  font-size: 2rem;
  line-height: 1.6;
  margin-bottom: 4.8rem;
  color: #666;
}

.hero-buttons {
  display: flex;
  gap: 15rem;
  justify-content: center;
  cursor: pointer;
}
