/* PrivacyPolicy.css */

.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  color: #363636;
}

.privacy-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

.privacy-effective-date {
  text-align: center;
  font-size: 1em;
  color: #555;
}

.privacy-intro {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.privacy-section {
  margin-bottom: 20px;
}

.privacy-heading {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.privacy-text {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
}

.privacy-link {
  color: #007bff;
  text-decoration: underline;
}
