.industries-section {
  background-color: #fefefe;
  padding: 12.8rem 0;
}

.industries-wrapper {
  text-align: center;
}

.industries-base {
  height: 100%;
}

.industries-tablist {
  height: 100%;
}

.industries-tab {
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: fit-content;
}

.industries-title {
  margin-top: 1rem;
}

.industries-cursor {
  background-color: rgba(187, 0, 0, 0.5);
  border-radius: 24rem;
  max-width: 6rem;
  max-height: 6rem;
  left: 0;
  right: 0;
  margin-inline: auto;
}

.industries-subtitle {
  font-style: italic;
  margin-top: 3rem;
}

.industries-description {
  font-size: 2rem;
  padding-left: 7rem;
  padding-right: 7rem;
}

.industries-divider {
  margin-top: 6rem;
}

.industries-pitch {
  text-align: center;
  font-size: 2rem;
  margin-top: 6rem;
  padding-left: 12rem;
  padding-right: 12rem;
}

.group[data-selected='true']
  .group-data-\[selected\=true\]\:text-default-foreground
  .industries-title {
  color: hsl(
    var(--nextui-default-foreground) /
      var(--nextui-default-foreground-opacity, var(--tw-text-opacity))
  );
  font-weight: 600;
}
