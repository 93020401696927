/* Container */
.meet-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 4rem 2rem;
  text-align: center;
  margin-bottom: 4rem;
}

/* Heading & Intro */
.meet-container h2 {
  font-size: 3rem; /* Larger main heading */
  margin-bottom: 1rem;
  font-weight: 700;
  color: #333;
}

.meet-intro {
  font-size: 1.25rem; /* Increased intro size */
  color: #555;
  max-width: 700px;
  margin: 0 auto 3rem;
  line-height: 1.6;
}

/* Single Card (Larry) */
.single-featured {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.featured-card {
  border-left: 5px solid #bb0000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Team Grid */
.team-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;
}

/* Keyframes for fade-up animation */
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Team Card */
.team-card {
  background-color: #fff;
  border-left: 4px solid #bb0000;
  border-radius: 8px;
  padding: 2.5rem;
  max-width: 360px; /* Wider to accommodate bigger text */
  width: 100%;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  /* Fade-up animation */
  opacity: 0;
  transform: translateY(15px);
  animation: fadeUp 0.5s ease forwards;
}

/* Hover Effect */
.team-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

/* Initial Circle */
.initial-circle {
  width: 90px;
  height: 90px;
  margin: 0 auto 1.2rem;
  border-radius: 50%;
  background: linear-gradient(135deg, #bb0000, #ff6b6b);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem; /* Larger initials */
  font-weight: 700;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for the circle */
.team-card:hover .initial-circle {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(187, 0, 0, 0.3);
}

/* Text styling inside each card */
.team-card h3 {
  font-size: 1.75rem; /* Bigger name */
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #333;
}

.team-card h4 {
  font-size: 1.3rem; /* Bigger role */
  margin-bottom: 1rem;
  color: #666;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.team-card p {
  font-size: 1.15rem; /* Bigger body text */
  color: #555;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 280px; /* Wider paragraph area */
}
